<template>
    <div id="apps">
        <div class="weitg clear">
            <div class="head clear">
                <p>审核未通过</p>
                <img src="../assets/qiandai1.png"/>
            </div>
            <div class="content clear">
                <ul class="clear">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                        <van-list
                                v-model="loading"
                                :finished="finished"
                                finished-text="没有更多了"
                                @load="onLoad"
                        >
                            <li v-for="(item,index) in list">
                                <div class="top clear">
                                    <div class="legy">
                                        <img src="../assets/jinbi.png">
                                        <div class="lef">
                                            <h3>微信号:{{item.wxNo}}</h3>
                                            <span>{{item.createTime}}</span>
                                        </div>
                                    </div>
                                    <div class="righ">
                                        <span>{{item.amount}}元</span>
                                        <span @click="toggleShow(index)"
                                              :class="{'on':item.hide?true:false}">查看原因</span>
                                    </div>
                                </div>
                                <p v-if="item.hide?true:false">{{item.failMsg}}</p>
                            </li>
                        </van-list>
                    </van-pull-refresh>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
  import {getUserWithdrawalFailed} from "@/api/user";

  export default {
    name: 'app',
    components: {},
    data () {
      return {
        loading: false,
        finished: false,
        refreshing: false,
        pageNum: 1,
        pages: 1,
        list: [
        ],
        total: 0
      }
    },
    methods: {
      toggleShow (index) {
        let obj = this.list[index];
        obj.hide = !obj.hide;
        this.$set(this.list, index, obj);
      },
      loadList() {
        if (this.pageNum > this.pages) {
          this.loading = false;
          return
        }
        this.loading = true
        getUserWithdrawalFailed().then(data => {
          if (data.success == true) {
            this.pageNum = data.data.data.current + 1;
            this.pages = data.data.data.pages;
            this.list.push(...data.data.data.records);
            this.total = data.data.totalIncomeAmout
            if (this.pageNum > this.pages) {
              this.finished = true;
            }
          }
        }).finally(() => {
          this.loading = false
        })
      },
      onRefresh () {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      onLoad () {
        if (this.refreshing) {
          this.list = [];
          this.pageNum = 1;
          this.pages = 1;
          this.refreshing = false;
        }
        this.loadList()
      }
    }
  }
</script>
<style>
    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

    body {
        background: #F8F8F8;
    }

    .weitg {
        width: 100%;
    }

    .weitg .head {
        width: 100%;
        background: #E7402E;
    }

    .weitg .head p {
        color: rgba(255, 255, 255, 100);
        font-size: 13px;
        display: inline-block;
        margin: 6% 0 4% 5%;
        font-family: '方正准圆-标准';
    }

    .weitg .head img {
        display: inline-block;
        float: right;
        width: 17%;
        margin: 5% 6% 8% 0;
    }

    .weitg .content {
        background: #fff;
        width: 100%;
    }

    .weitg .content ul li {
        clear: both;
        overflow: hidden;
        border: 1px solid #F8F8F8;
    }

    .weitg .content ul li .top .legy {
        width: 55%;
        float: left;
    }

    .weitg .content ul li .top .legy img {
        width: 18%;
        display: inline-block;
        float: left;
        margin: 4%;
    }

    .weitg .content ul li .top .legy .lef {
        width: auto;
        margin: 4% 0 0 0;
    }

    .weitg .content ul li .top .legy .lef h3 {
        font-weight: 400;
        color: rgba(132, 132, 132, 100);
        font-size: 12px;
        display: block;
        font-family: SourceHanSansSC-regular;
    }

    .weitg .content ul li .top .legy .lef span {
        display: block;
        color: rgba(204, 204, 204, 100);
        font-size: 12px;
        font-family: 方正准圆-标准;
    }

    .weitg .content ul li .top .righ {
        width: 40%;
        float: right;
        margin-left: 5%;
        margin-top: 4%;
    }

    .weitg .content ul li .top .righ span {
        color: rgba(227, 8, 8, 100);
        font-size: 13px;
        font-family: 方正准圆-标准;
    }

    .weitg .content ul li .top .righ span:nth-child(2) {
        margin-left: 3%;
        color: rgba(16, 16, 16, 100);
        padding-right: 20px;
        background: url(../assets/xiangshang.png) right center no-repeat;
        background-size: 22px;
    }

    .weitg .content ul li p {
        color: rgba(87, 87, 87, 100);
        font-size: 12px;
        display: block;
        margin: 5% 5% 8% 5%;
        line-height: 18px;
        font-family: 方正准圆-标准;
    }

    .weitg .content ul li .top .righ span.on {
        background: url(../assets/xiangxia.png) right center no-repeat;
        background-size: 22px;
    }
</style>
